.fieldGroup{
    border: 1px solid #3a3f48;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    margin-bottom: 10px;
}
.fieldGroup> .fieldElement{
    display: flex;
} 
.fieldGroup> .fieldElement > label{
    margin-right: 1rem;
} 
.fieldGroup> .fieldElement > textarea,
.fieldGroup> .fieldElement > input{
    flex: 1;
} 
.ViewListCont{
    height: calc(100vh - 305px);
    overflow-y: scroll;
}
.fields__new{
    flex-direction: column !important;
}

.add_field{
    border: 1px solid #73367b;
    cursor: pointer;
    width: 20%;
    border-radius: 4px;
    text-align: center;
    margin-left: 40%;
    background-color: #b2b2b2;;
}

.delete__button{
    margin-left: 80%;
    margin-bottom: 2rem;
}
.info__template__doc{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    max-height: 15rem;
    overflow-y: scroll;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #e9ecef;
    opacity: 1;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.body__listModal {
    max-height: 22rem;
    overflow-y: scroll;
}

.body__listModal input{
    margin-right: 3rem;
}
.info__catgModa{
    align-items: center;
    margin-bottom: 1rem;
}