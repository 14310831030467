.modal_firm_document {
  .modal-body {
    padding-left: 5rem;
  }
  .form_sign_header {
    h5 {
      color: $custom_purple;
      font-weight: 600;
    }
    .subtitle{
      color: $custom_gray;
      font-weight: 400;
    }
  }

  .signer_input {
    border: 3px solid $custom_purple;
    border-radius: 20px;
    input {
      border-radius: 20px;
      border: none;
      height: 100%;
    }
  }
  .clear-select {
    display: inline-block;
    border: none;
    padding: 0;
    width: min-content;
    min-width: 45px;
  }
  .btn_firm,
  .btn_firm_alt {
    i {
      color: $custom_purple;
      /* text-align: center; */
      justify-content: center;
      align-items: center;
    }
    background-color: transparent;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: $custom_purple;
    font-weight: 600;
    border: 2px solid $custom_purple;
  }
  
    .btn_firm_alt {
      i {
        color: white;
      }
      background-color: $custom_purple;
      color: white;
    }
  .signer_input_group {
    .principal_input {
      width: min-content;
      input {
        width: min-content;
      }
    }
    .btn_firm {
      i {
        color: $custom_gray;
        /* text-align: center; */
        justify-content: center;
        align-items: center;
      }
      background-color: transparent;
      padding: 0.5rem 1rem;
      border: none;
      color: $custom_gray;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .signer_container {
    p{
      margin: 0;
    }
    .h6 {
      font-weight: 600;
      font-size: 15px;
      text-transform: capitalize;
    }
    .url_container{
      background-color: rgba(128, 128, 128, 0.3);
      color: $custom_gray;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      a{
        color: $custom_gray;
        margin: 0;
        padding: .5rem 1rem;
      }
      button{
        border: none;
        border-left: 1px solid $custom_gray;
        background-color: transparent;
        color: $custom_gray;
        padding: .5rem 1rem;
      }
    }
  }
}
