.modal__container {
  position: absolute;
  top: 6rem;
  width: 90%;
  height: 80%;
  background-color: #ffffff;
  left: 4%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.title__peticion {
  // margin-top: 1rem;
  margin: 0;
  margin-bottom: inherit;
  text-align: center;
  height: 10%;
}

.title__peticion h1 {
  font-size: 150%;
}
.document__content {
  overflow-y: scroll;
  height: 65vh;
}

.document__content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.document__content::-webkit-scrollbar-thumb {
  background: #73367b;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.document__content::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.document__content::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
.btn__close {
  background-color: #73367b;
  color: white;
  border: none;
  border-radius: 40px;
  width: 2%;
  height: 5%;
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  outline: none;
}

.modal__row {
  width: 100%;
  display: flex;
}
.MsoNormalTable{
  width: 100%;
  text-align: center;
}



@media (max-width: 817px) {
  .modal__container {
    height: auto;
    position: absolute;
    z-index: 1000;
    margin-bottom: 20px;

    .modal__row {
      flex-direction: column;
      align-items: center;
      
    }
  }
  .document__content {
    height: 35rem;
  }
  .btn__close{
    height: 26.75px;
  }
}

@media (max-width: 500px) {
  .modal__container {
   top: 10rem;  
  }
}


@media (min-height: 1024px) {
  .document__content {
    height: 58rem;
  }
}

@media (min-height: 1336px) {
  .document__content {
    height: 58rem;
  }
}
