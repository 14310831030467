/* USER PROFILE PAGE */
.card {
  padding: 30px;
  background-color: rgba(214, 224, 226, 0.2);
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
}
.card-info
{
  padding-bottom: 30px;
}
.height75 {
  max-height: 75%;
}


.btn-select {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
}
.card-title-list{
    font-size: 1.7rem;
}



