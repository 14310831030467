.header__panel {
  width: 100%;
  margin: 0;
  padding: 0;
}

.img__home img,
.img__homeAdmin img {
  width: 8rem;
}

.img__homeAdmin {
  display: flex;
  justify-content: space-between;
}

.logo__admin {
  margin: 3rem 2rem;
  width: 18rem !important;
  height: 5rem;
}
.img__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5rem;
  padding: 2rem;
  margin-top: 1rem;
}

.img__logo img {
  width: 14rem;
}
.img__izq {
  display: flex;
  justify-content: flex-end;
}
.img__logo,
.img__izq {
  width: 40%;
}

.create__panel {
  display: flex;
  width: 20%;
  align-items: flex-end;
  height: 7rem;
}

.text_create {
  text-align: center;
  color: #73367b;
}

h6 {
  padding: 0;
  margin: 0;
}

.text_create hr {
  width: 50%;
  height: 1px;
  background-color: #73367b;
  margin-top: 5px;
}
.admin__board{
  width: 10rem;
}
@media (max-width: 708px) {
  .img__izq {
    visibility: hidden;
    width: 0;
  }
  .header__panel {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 447px) {
  .img__izq {
    visibility: hidden;
    width: 0;
  }
  .header__panel {
    display: flex;
    flex-direction: column;
    height: 12rem;

    .create__panel {
      display: block;
      width: 60%;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 766px) {
  .sidebargle{
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3rem;
  }
}