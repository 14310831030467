.loader-page {
    background: rgb(255, 255, 255);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:all .3s ease;
  }
  .there-no-document{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column; /* defined the main axis */
    justify-content: center; /* y-axis */
    align-items: center; /* x-axis */
  }
  .loader-page::before {
    content: "";
    position: absolute;
    border: 2px solid rgb(207, 54, 187);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-sizing: border-box;
    border-left: 2px solid rgba(50, 150, 176,0);
    border-top: 2px solid rgba(50, 150, 176,0);
    animation: rotarload 1s linear infinite;
    transform: rotate(0deg);
  }
  @keyframes rotarload {
      0%   {transform: rotate(0deg)}
      100% {transform: rotate(360deg)}
  }
  .loader-page::after {
    content: "";
    border: 2px solid rgba(214, 89, 214, 0.5);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-sizing: border-box;
    border-left: 2px solid rgba(199, 93, 194, 0);
    border-top: 2px solid rgba(185, 62, 179, 0);
    animation: rotarload 1s ease-out infinite;
    transform: rotate(0deg);
  }