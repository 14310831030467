.card-title {
  font-size: 1rem;
  cursor: pointer;
}

.col {
  cursor: pointer;
}

.deseas__select {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  margin: 1rem 0;
}

.deseas__select input {
  margin-left: -2rem;
}

.list__dynamic {
  width: 100%;
  height: auto;
  padding: 1rem;
  overflow-y: scroll;
  margin-bottom: 1rem;
}
.list__dynami{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.list__dynamic::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}
.list__dynamic::-webkit-scrollbar-thumb {
  background: #73367b;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.list__dynamic::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.list__dynamic::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.shadow {
  margin: 0 1rem 1rem 0;
}

.header__field {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 1.5rem 0 1.5rem;
  align-items: center;
  padding: 1rem;
}
.header__field__purchase {
  display: flex;
  justify-content: left;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 1.5rem 0 1.5rem;
  align-items: center;
  padding: 1rem;
}
.image__list {
  font-size: 2rem;
  display: flex;
  align-items: center;

  justify-content: center;

  align-content: center;
  text-align: center;
  padding: 0.83333rem;
  margin: 0 15px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-radius: 100%;
  border: 3px solid $custom_purple;
  color: $custom_purple;
  width: 60px;
  height: 60px;
}
.text__information {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  p, h4 {
    color: $custom_purple;
    margin: 0;
    padding: 0;
  }
}

.create__or__search {
  display: flex;
  justify-content: space-between;
  .btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgb(52 58 64 / 40%),
      0 0.0625rem 0.125rem rgb(52 58 64 / 50%);
    cursor: pointer;
    border: 1px solid black;
    margin-right: 1em;
  }
  .btn-secondary{
    background-color: $custom_purple;
    border: 1px solid $custom_purple;
  }
}

.btn-border{
  border-radius: 5px 0 0 5px !important;
}

.btn-search {
  display: flex;
  align-items: center;
  box-shadow: 0 0.125rem 0.625rem rgb(22 170 255 / 40%),
    0 0.0625rem 0.125rem rgb(22 170 255 / 50%);
  color: #fff;
  background-color: #ef4df5;
  border-color: #ef4df5;
  cursor: pointer;
}

.btn-search:hover {
  color: #fff;
  background-color: #0098ef;
  border-color: #0090e2;
}

.create__or__search .active {
  width: 330px;
}

.create__or__search {
  position: relative;
  margin-right: 0.66667rem;
}

.search-input{
  border: 1px solid black;
  border-radius: 0 5px 5px 0;
}

.input-search{
  margin-right: 10px;
}

button:focus {
  box-shadow: none !important;
  outline: none !important; 
}

.main-footer{
  background: rgba(128, 126, 126, 0.45);
  height: 2.8rem;
  align-items: center;
  display: flex;
  justify-content: space-around;
}


@media (max-width: 562px) {
  .header__field{
    margin: 1.5rem 0 0;
    
  }
  .image__list {
    margin: 0;
  }
  .text__information {
    margin-top: 1rem;
  }
  
  .create__or__search {
    margin-top: 1rem;
  }
  }

