.preview_document{
    position: relative;
    background-color: $custom_purple;
    background: linear-gradient(225deg, $custom_purple 0%, #461b42 100%);
    padding: 2em;
    .close_preview_document{
        
        position: absolute;
        height: 40px;
        width: 40px;
        top: 0;
        right: 0;
        background-color: transparent;
        border: none;
        border-radius: 100%;
        & .cls-1, & .cls-2{
          transition: fill .3s ease-in-out;
        }
        &:hover {
          .cls-1{
            fill: $custom_purple;
          }
          .cls-2{
            fill: white;
          }
        }
    }
    &> .preview_document_content{
        min-height: calc(100vh - 120px);
      background-color: white;
      height: 100%;
      border-radius: 15px;
      padding: 1em;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      h6 {
        
        font-weight: 600;
        margin-bottom: 1em;
        text-align: center;
        font-size: 20px;
      }
      p {
        word-wrap: break-word;
      }
    }
}