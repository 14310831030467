@import url("https://fonts.googleapis.com/css?family=Numans");

.login__page {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  background: url("../../static/fondo.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  position: relative;
  height: 100vh;
}

.text-title1{
  color: #ffffff8e;
  font-size: 1.5rem;

}

.form__login {
  padding: 0;
  margin: 0;
  width: 100%;
}

.login__body {
  display: flex;
  justify-content: center;
  box-shadow: rgba(63, 63, 66, 0.2) 0px 7px 29px 0px;
  border: 1px solid #777;
  border-radius: 10px;
  width: 55%;
  height: 70%;
  top: 8rem;
  position: absolute;
}

.login__body img {
  width: 90%;
}

.logo__img {
  width: 30%;
  display: flex;
  align-items: center;
}

.login__info {
  width: 70%;
  position: relative;
  display: inline-block;
}

.login__admin {
  width: 100%;
  position: relative;
  display: inline-block;
}

.login__admin img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 900px) {
  .login__body {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    border: none;
  }
  .login__info {
    width: 100%;
    position: relative;
    display: inline-block;
    height: 18rem;
  }
}

.login__info img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.formulario {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.formulario FORM {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}
.form-group2{
  display: grid;
  justify-content: center;
  width: 100%;
  margin-bottom:1rem;
}

.input-group div,
.login_btn {
  width: 60%;
}

@media (max-width: 750px) {
  .input-group div,
  .login_btn {
    width: 80%;
  }

}

@media (max-width: 510px) {
  .login__body {
    width: 80%;
  }
}

@media (max-width: 380px) {
  .login__body {
    width: 90%;
  }
}

.login_btn {
  border-radius: 10px;
  height: 2.3rem;
  color: #330c3b;
  font-weight: bold;
}
.input-group input {
  background: rgba(255, 255, 255, 0.8);//hsl(290, 66%, 14%);
}

.input-group input::-webkit-input-placeholder {
  text-align: center;
  line-height: 100px; /* Centrado vertical */
}

.contra__login {
  margin-right: 0.4rem;
  color: aliceblue;
}
