$custom_purple: #93378a;
$custom_gray: gray;
$custom_gray_transparent: rgba(128, 128, 128, 0.701);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
)