@import "./Variables.scss";
@import "./components/MercadoPagoModal.scss";
@import "./components/DraggableVariables.scss";
@import "./components/TemplateDocumentInputs.scss";
@import "./components/DragArea.scss";
@import "./components/AddList.scss";
@import "./components/InputDateCustom.scss";
@import "./components/PreviewDocument.scss";
@import "./components/FirmModal.scss";
// Import Custom SCSS
@import "global.scss";

.close_modal_stepper{
  position: absolute;
  height: 40px;
  width: 40px;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  border-radius: 100%;
  & .cross-cls-1, & .cross-cls-2{
    transition: fill .3s ease-in-out;
  }
  &:hover {
    .cross-cls-1{
      fill: $custom_purple;
    }
    .cross-cls-2{
      fill: white;
    }
  }
}

.drag_attachments_modal{
  .modal-content{
    padding: 1em 2em
  }
  .modal-title{
    color: $custom_purple;
    text-align: center;
    font-weight: 700;
  }
  .close_btn{
    background: transparent;
    border: none;
    border-radius: 100%;
    color: $custom_purple;
    font-weight: 700;
    width: 50px;
    .cls-1{
      fill:white
    }
  }
  .primary_custom_btn {
    height: 35px;
    width: 30%;
    min-width: 50px;
    max-width: 300px;
    margin: 0 5px;
  }
  
}


.primary_custom_btn {
  border-radius: 15px;
  border: none;
  color: white;
  background-color: $custom_purple;
}
.secondary_custom_btn {
  border-radius: 15px;
  border: none;
  color: white;
  background-color: $custom_gray;
}
.show_detail{
  .icon_option{
    &:hover{
      color: $custom_purple;
    }
  }
}

html {
  margin: 0;
  font-family: 'Muli', sans-serif;
  min-height: 100%;
  position: relative;
  background-color: #ffffff;
}

*,
*::before,
*::after  {
  box-sizing: border-box;
}
