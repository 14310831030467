.error-container {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: montserrat, sans-serif;
  position: absolute;
  top: 0;
}

.big-text {
  font-size: 200px;
  font-weight: 900;
  font-family: sans-serif;
  background: url(https://cdn.pixabay.com/photo/2018/05/30/15/39/thunderstorm-3441687_960_720.jpg)
    no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;

}

.small-text {
  font-family: montserrat, sans-serif;
  color: rgb(168, 49, 158);
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
