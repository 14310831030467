.container__register {
    background-color: #ffffff;
    background: url("../../static/fondo.jpg") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    height: 100vh;
    box-shadow: rgba(63, 63, 66, 0.2) 0px 7px 29px 0px;
    border: 1px solid #777;
}



.form-conf {
    top: 7rem;
    position: absolute;
    border: 1px solid gray;
    border-radius: 14px;
    width: 60%;
    box-shadow: gray;
}

.form-conf form{
    width: 60%;
}

.form-conf .image__register{
    width: 30%;
    display: flex;
    align-items: center;
}
.form-conf img{
    margin-top: 15%;
    margin-bottom: 5%;
    width: 16rem;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 1s infinite  alternate;
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}



@media (max-width: 750px) {
    .form-conf form{
        width: 100%;
        padding: 1em;
    }
    .form-conf {
        width: 90%;
        display: flex;
        flex-direction: column-reverse;
    }
    .form-conf .image__register{
        width: auto;
        display: flex;
        justify-content: center;
    }
    .form-conf img{
        margin-top: 7%;
        margin-bottom: 0;
    }
    
    
}

@media (max-width: 1100px) {
    .form-conf {
        width: 80%;
    }
    
}