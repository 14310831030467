.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  position: relative;
  min-height: 100px;
  border: 4px solid #d0d7de;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.image-upload-wrap:hover {
  background-color: transparent;
  border: 4px dashed #d0d7de;
}

.text-information {
  margin-top: 30px;
  text-align: center;
}

.file {
  margin-left: 5px;
  display: flex;
  flex-direction: column;

}

.file__img {
  width: 6rem;
  height: 6rem;
}

.file__name {
  margin-top: 3px;
  text-align: center;

}

.dropzone_input_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  width: 100%;
  border: 2px solid $custom_purple;
  color: $custom_purple;
  padding: 1.5em;
  border-radius: 10px;

  &.dropzone_file_loaded {
    color: white;
    background-color: $custom_purple;
  }

  .dropzone-icon {
    padding: 1.2em;
    margin-bottom: .5em;

    &.dropzone-icon-loaded {

      border: 3px solid white;
      border-radius: 100%;
    }
  }
}