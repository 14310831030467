/***********************************************
STYLES OF FOOTER
***********************************************/
.footer {
  margin-top: 1.9rem;
  width: 100%;
}
.footer,
.profiles__panel {
  display: flex;
  justify-content: space-between;
}
.profiles__panel {
  display: flex;
  align-items: center;
}

.img__profiles {
  display: flex;
  flex-direction: column;
  // margin-right: 1rem;
  align-items: center;
  width: 120px;
}

.img__profiles img {
  width: 2.5rem;
  cursor: pointer;
}
.img__profiles .icon__profiles {
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #87207c;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}

.img__profiles span {
  cursor: pointer;
}
.soporte__panel {
  display: flex;
  align-items: flex-end;
}
.soporte__panel img {
  width: 8rem;
}

.back__panel {
  margin-bottom: 2rem;
}

.back__panel figure {
  margin-bottom: 1px;
}

.back__panel figure img {
  margin-right: 1rem;
  width: 2.4rem;
}

.back__panel .back {
  cursor: pointer;
}

.footer__login {
  display: flex;
  flex-flow: row-reverse;
  position: absolute;
  bottom: 0;
  right: 0;
}
.footer__profile {
  margin: 0;
  background: -webkit-linear-gradient(left, #c572b7, #cf37cf);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

// @media (max-width: 708px) {
//   .img__derFooter {
//     visibility: hidden;
//     width: 0;
//   }
// }
@media (max-width: 817px) {
  .modal_show_footer {
    position: relative;
    top: -50vh;
  }
}

@media (max-width: 708px) {
  .img__derFooter {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .footer {
    display: flex;
    flex-direction: column;
    .profiles__panel {
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .img__profiles {
        // margin: 0 0 2rem;
        // width: 120px;
        // margin: 0.5rem 1rem 0rem;
      }
    }
    .soporte__panel {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
    }
  }
}
