.main__payment {
  /*   background: url("../../static/fondo.jpg") no-repeat;
  background-size: 100% 100%; */
  text-align: center;
  min-height: 23.9rem;
  color: #73367b;
  height: 59.5vh;
}

.main__payment section {
  margin: 0;
  padding: 0;
  align-items: baseline;
}

.title__documentos {
  margin-top: 1rem;
}

.title__documentos img {
  margin-right: 0.5rem;
  width: 3rem;
  height: 3rem;
}

.title__documentos hr {
  width: 30%;
  height: 0.3px;
  background-color: #73367b;
  margin-top: 5px;
  margin-bottom: 0;
}
.names__documentos h5 {
  font-size: 1rem;
  margin: 0;
  margin-top: 5px;
}
.names__documentos span {
  font-size: 0.7rem;
  color: #b2b2b2;
}
.selection__pay {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  padding-top: 2rem;
}
.download-img img{
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 0.5s infinite  alternate;
}

.selection__pay img {
  width: 70%;
  margin-bottom: 1rem;
  cursor: pointer;
}

.selection__pay h5 {
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 520px) {
  .selection__pay {
    width: 80%;
  }
}

@media (max-width: 708px) {
  .title__documentos {
    margin-top: 0;
  }
  .selection__pay {
    padding-top: 0.5rem;
  }
}
@media (max-width: 817px) {
  .main__payment {
    
    height: max-content;
  }
  
}
@media (min-height: 1024px) {
  .main__payment {
    padding: 0;
    min-height: 46.5rem;
  }
}

@media (min-height: 1336px) {
  .main__payment {
    padding: 0;
    min-height: 67rem;
  }
}
