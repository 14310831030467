.body__home {
  display: flex;
}

.body__information {
  width: inherit;
  height: calc(100vh - 56px);
  overflow-y: scroll;
  padding: 1rem;
  background-color: #ffffff;
  background: url("../../static/fondo.jpg") no-repeat;
  background-size: 100% 100%;
}

.body__information::-webkit-scrollbar,
.sb-sidenav::-webkit-scrollbar {
  width: 8px;
}

.body__information::-webkit-scrollbar-thumb {
  background: #73367b;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.body__information::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.body__information::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
