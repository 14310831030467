.body__profile {
  width: 100%;
  height: 92vh;
  background-color: #ffffff;
  background: url("../../static/fondo.jpg") no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  display: flex;
}
.info__profile {
  display: flex;
  flex-direction: column;
  width: 35%;
  background: -webkit-linear-gradient(left, #c572b7, #cf37cf);
  height: 100%;
  padding: 4rem 0 0 5%;
  position: relative;
}
.info__profile .imagen__logo__profile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
  cursor: pointer;
}

.show__detail {
  width: 65%;
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  color: #3931af;
  position: relative;
  overflow-y: scroll;
}

.perfil__data {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.info__profile h2 {
  text-align: center;
}

.photo__perfil img {
  width: 6rem;
  margin-left: 3rem;
  margin-right: -1rem;
}

.up__data {
  margin-top: -1rem;
}
.up__data p,
.cajas__info p {
  margin-top: 0;
  margin-bottom: 0;
}

.up__data button {
  width: 100%;
  font-size: 0.9rem;
  height: 2rem;
  margin: 0.3rem 0 0.3rem 0;
  border: rgb(128, 128, 128) 2px dashed;
  background-color: rgb(233, 233, 233);
}

.cajas__info {
  margin-left: 2rem;
  padding-left: 2rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem 0 0 1rem;
  cursor: pointer;
}

.cajas__info:hover {
  background-color: white;
  color: #3931af;
}

.active {
  background-color: white;
  color: #3931af;
}
.fields__show {
  margin: 0 0 1.5rem 1rem;
}

.fields__show h6 {
  margin-bottom: 0.5rem;
}

.fields__show input {
  cursor: not-allowed;
  width: 100%;
}
.show__info button {
  margin-left: 6rem;
  background-color: #cf37cf !important;
}
.input__edit {
  cursor: text !important;
}
@media (max-width: 668px) {
  .body__profile {
    height: auto;
    display: block;
    .info__profile,
    .show__detail {
      width: 100%;
    }
  }

  .show__info button {
    margin-bottom: 1em;
  }

}

.body-style {
  display: flex;
  font-family: Sans-serif;
}

.button-style {
  color: white;
  background-color: #93378a;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: 1px solid #93378a;
  border-radius: 4px;
  &:hover {
    color: #93378a;
    border: 1px solid #93378a;
    background-color:  white;
  }
}

.body-style .img__404 {
  width: 40%;
}

.text__img__404 {
  margin-left: 5rem;
}
.text__img__404 h1 {
  font-weight: bold;
  font-size: 34px;
}

.text__4{
  font-size:25px;text-align:left;
}