@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Anton");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,300&display=swap");
$enable-deprecation-messages: false;


$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Condensed", sans-serif;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  background-color: #f4f4f7;
  font-family: 'Bungee Inline', cursive;
}

// Set spacing on containers for page symmetry

.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

h1 {
  font-family: "Anton", sans-serif;
}

a.link-unstyled {
  color: inherit;
}

a.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}


.btn.btn-primary {
  color: #fff;
  background-color: #7dcd40;
  border-color: #7dcd40;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #7dcd40;
  border-color: #7dcd40;
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #7dcd40;
  border-color: #7dcd40;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 205, 64, 0.5);
}

.btn.btn-danger {
  color: #fff;
  background-color: #cd4040;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #cd4040;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #cd4040;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 64, 64, 0.5);
}

a {
  text-decoration: none;
}

@for $i from 1 through 20 {
  #react-select-#{$i}-listbox > div{
    max-height: 160px!important;
  }
}
.attachmentFile:hover > td {
  background-color: #eff6fc!important;
  --bs-table-accent-bg: #eff6fc!important;

}

.form-check-input:checked{
  background-color: $custom_purple;
  border-color: $custom_purple;
}

.ch-100 {
  &>*{
    height: 100%!important;
  }
}
.hide_input_select {
  input {
    display: none;
  }
}


/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: $custom_purple;
  border: 0px none $custom_purple;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}