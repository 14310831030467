.rigth__bar {
  display: inline;
}

.contains__bar {
  padding: 0.5rem 1rem 0.5rem;
  font-size: 1rem;
  width: 35vw;
  height: 65vh;
  overflow-y: scroll;
}
.title__select {
  text-align: initial;
}
.title__select hr {
  width: 20%;
  height: 1px;
  background-color: #73367b;
  margin-top: 5px;
  display: inline-block;
}

.type__select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.type__select > div {
  border: 1px solid #73367b;
  width: 48%;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.select__name {
  background-color: #73367b;
  color: white;
}

.type__select > div:hover {
  background-color: #73367b;
  color: white;
}
.body__data {
  display: flex;
  flex-direction: column;
}

.text-left p{
  margin: 0;
  padding: 0;
  padding-top: 1rem;
}
.input_vars{
  margin: 0;
  padding: 0;
  width: 100%;
}
.select_list_doc button{
  width: 100%;
  margin: 14px 0 14px 0;
  font-size: small;
  overflow: hidden;
}

.body__data input {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.button_major {
  display: flex;
  justify-content: center;
}

.button_select {
  width: 70%;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.button_select button {
  width: 40%;
  border-radius: 4px;
}

.button_select button:focus {
  background-color: #73367b;
  color: white;
}

@media (max-width: 1110px) {
  .button_select {
    width: 100%;
  }
}

@media (max-width: 1090px) and (min-width: 817px) {
  .type__select {
    flex-direction: column;
    align-items: center;
  }
  .type__select > div {
    border: 1px solid #73367b;
    width: 80%;
  }
}
@media (max-width: 817px) {
  .rigth__bar {
    display: block;
    .type__select {
      width: 80%;
      justify-content: space-around;
      margin-top: 1rem;
    }
    .type__select > div {
      border: 1px solid #73367b;
      width: 48%;
    }
  }
  .contains__bar {
    padding: 3rem;
    font-size: 1rem;
    width: 100%;
    height: max-content;
    overflow-y: scroll;
  }
}

@media (max-width: 517px) {
  .rigth__bar {
    display: block;
    width: 100%;
  }
}
