.reset__pass {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  text-align: center;
}

.reset__pass .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 70%;
}

.reset__pass .form--wrapper {
    margin: 32px 0;
  
}

.reset__pass .form--error {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
