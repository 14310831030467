.payment-mercadopago-input{
    & > label{
        height: 30%;
    }
    & > div{
        height: 70%;
        padding: 0.375rem 0.75rem;
        border-radius: 0.375rem;
        border: 1px solid #ced4da;
    }
}