.table-responsive{
    padding: 0.2rem;
}

.card-users{
    margin: 0 5px 0 5px;
}
.table-bordered .buttones{
    padding-bottom: 0;
    display: flex;
    height: 68.22px;
}
.buttones button{
    margin-bottom: 0;
    height: fit-content;
}
.bole{
    width: fit-content;
}

.esfera {
    width: 12px;
    height: 12px;
    -ms-border-radius: 150px;
    -moz-border-radius: 150px;
    -webkit-border-radius: 150px;
    -o-border-radius: 150px;
    border-radius: 150px;
    margin: 0px 7px 0px 0px;
  }

.color{
    background: radial-gradient(at top left, rgb(19, 214, 68) 20%, #040);
}
.color-danger{
    background: radial-gradient(at top left, rgb(226, 50, 19) 20%, rgb(68, 5, 0));
}

#dataTableHead{
    background-color: rgb(199, 98, 194);
}