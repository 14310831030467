.body__panel {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  background: url("../../static/fondo.jpg") no-repeat;
  background-size: 100% 100%;
  // height: 100vh;
}

.main__panel {
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 59.4vh;
}
.container__panel {
  padding: 0;
  width: 80%;
  max-height: 24rem;
}

.selection__panel,
.back__panel {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid #73367b;
  color: #73367b;
}

.back__panel figure img {
  border-radius: 65px;
  height: 35px;
  width: 40px;
}
.selection__panel input {
  width: 30%;
  background: url("../../static/lupa.png") no-repeat;
  background-size: 2.5rem;
  background-position: 100%;
  border: 2px solid #73367b;
  border-bottom: none;
}

.selection__panel ::-webkit-input-placeholder {
  color: #73367b;
}

.list__categories {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  overflow-y: scroll;
  max-height: 40vh;
  overflow-x: hidden;
}

.list__categories::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

.list__categories::-webkit-scrollbar-thumb {
  background: #73367b;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.list__categories::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.list__categories::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.categories {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
 
}

.categorie{
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50px;

}
.list__search {
  min-height: 22rem;
  border: 1px solid #73367b;
}

.link {
  color: #73367b;
}

.link:hover {
  text-decoration: none;
}

.categories:hover {
  color: blue;
}

.categories img {
  width: 3rem;
  height: 3rem;
  margin-right: 0.7rem;
}

.data__cat span{
  font-size: 0.8rem;
}

@media (max-width: 400px) {
  .selection__panel input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
  }
  .selection__panel input {
    border: none;
    outline: none;
  }
  .list__categories {
   
    max-height: 30vh;
  }
  
}

@media (max-width: 480px) {
 .main__panel{
  min-height: 45vh;
 }
 .list__categories {
   
  max-height: 30vh;
}

  
}

@media (min-width: 480px) and (max-width: 537px) {

  .main__panel {
    height: auto;
    min-height: 50vh;
  }
  
  
}
@media (min-width: 537px) and (max-width: 700px) {
  .main__panel{
    min-height: 55vh;
  }
  
}


@media (min-height: 1024px) {
  .main__panel {
    padding: 0;
    min-height: 46.5rem;
  }
}

@media (min-height: 1336px) {
  .main__panel {
    padding: 0;
    min-height: 67rem;
  }
}
