.input_date_custom_container{
    .input_date{
        & > input{

            width: 100%;
            border: 2px solid #93378a;
            height: 30px;
            border-radius: 15px;
            text-align: center;
        }
    }
    .date_selector_custom {
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        background-color: $custom_purple;
        &>svg{
            height: 15px;
            width: 15px; 
            position: absolute;
            inset: 0;
            left: calc(50% - 7.5px);
            top: calc(50% - 7.5px);
        }
    
        &>input {
            flex-direction: row-reverse;
            border: none;
            color: transparent;
            background-color: transparent;
            opacity: 1;
            position: relative;
            height: 15px;
            width: 15px;
    
            &:focus {
    
                opacity: 0
            }
    
            &::-webkit-calendar-picker-indicator {
                display: block;
                z-index: 20;
                background: transparent;
                color: transparent;
                cursor: pointer;
                height: 20px;
                position: absolute;
                top: auto;
                bottom: auto;
                left: auto;
                right: -10px;
                width: 20px
            }
        }
    }
}