@import "variables/navigation", "variables/spacing";

// Add styling for icons used within nav links
.nav,
.sb-sidenav-menu {
  .nav-link .sb-nav-link-icon {
    margin-right: 0.5rem;
  }
}

.search__input {
  width: 80%;
  border-radius: 5px 0 0 5px;
}

.btn__search {
  border-radius: 0px 5px 5px 0;
}
// Base styling for the topnav
.menu__info {
  top: 10px !important;
  max-width: 20rem;
}
.sb-topnav {
  padding-left: 0;
  height: $topnav-base-height;
  z-index: $zindex-topnav;
  .navbar-brand {
    width: $sidenav-base-width;
    padding-left: $navbar-padding-x;
    padding-right: $navbar-padding-x;
    margin: 0;
  }
  &.navbar-dark {
    #sidebarToggle {
      color: $topnav-dark-toggler-color;
    }
  }
  &.navbar-light {
    #sidebarToggle {
      color: $topnav-light-toggler-color;
    }
  }
}

.dropwon__list,
.dropwon__list:hover,
.dropwon__list:focus,
.dropwon__list:active,
.button__info button {
  background-color: transparent !important;
  border: transparent !important;
  outline-style: none;
}
