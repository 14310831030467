.droppable_page_title{
    display: flex;
}

.droppable_variables_list{
    min-height: 63px;
    margin-bottom: 10px;
    &.dragging_over{
        background-color: $custom_purple;
        border-radius: 5px;
    }
}
.droppable_page_title,.droppable_page_description {
    margin-bottom: 5px;
    
    margin-right: -15px;
    margin-left: -15px;
    input{
        border-radius: 15px;
        padding: 0 5px;
    }
    textarea{
        resize: none;
        width: 100%;
        border-radius: 15px;
    }
    h5{
        margin: 0;
        background-color: black;
        border: 3px solid black;
        color: white;
        font-size: 14px;
        padding: 3px 10px;
        border-radius: 20px;
        &.invalid{
            border-color: red;
        }
    }
    .edit_title_page_name_btn{
        border: none;
        background-color: white;
        color: $custom_purple!important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        span{
            display: inline-block;
            height: 20px;
            width: 20px;
            margin-right: 5px;
            border-radius: 100%;
            background-color: $custom_purple;
            color: white;
        }
        
    }
}
.add_page_btn{
    margin-top: 10px;
    font-size: 10px;
    border-radius: 15px;
    padding: 3px 8px 3px 5px;
    border: none;
    background-color: $custom_purple!important;
}