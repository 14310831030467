.document__peticion {
  display: flex;
  flex-direction: column;
  margin: 3rem;
  text-align: initial;
  padding: 1rem;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.document__peticion input {
  border: none;
  outline: none;
  background-color: #f4f4f7;
  font-weight: bolder;
}
.date__important,
.asunto {
  margin-bottom: inherit;
  cursor: pointer;
}

.entidad__importan,
.inf_person,
.hechos__peticion {
  display: flex;
  flex-direction: column;
  margin-bottom: inherit;
  cursor: pointer;
}
.hechos__peticion h2 {
  text-align: center;
}
.principal {
  margin-bottom: inherit;
  text-align: justify;
}
.text__large {
  display: flex;
  flex-direction: column;
  margin-bottom: inherit;
  width: 100%;
}

.text__large input {
  width: 100%;
}
