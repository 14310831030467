/*
.row {
  display: flex;
  justify-content: space-evenly;
}
*/
.data__template {
  width: 45%;
}

.data__template .form-group {
  flex-direction: column !important;
}

.jodit-container {
  margin: 28px;
}

.jodit-workplace {
  height: 20rem !important;
}

/* Style the input container */
.input-container {
  display: flex;
  margin-bottom: 15px;
}

/* Style the form icons */
.icon {
  display: flex;
  background: $custom_purple;
  color: white;
  min-width: 50px;
  /* text-align: center; */
  justify-content: center;
  align-items: center;
}

/* Style the input fields */
.input-field {
  width: 100%;
  outline: none;
}

.input-field:focus {
  border: 2px solid rgb(255, 30, 255);
}

.rich__text {
  margin: 1.5rem;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.rich-editor .rdw-editor-toolbar {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.rich-editor .rdw-editor-main {
  padding-left: 0.5rem;
  background-color: #fff;
}

.rich-editor .DraftEditor-editorContainer {
  padding: 1rem;
  height: 22rem;
  overflow-y: scroll;
}

.draft-editor-wrapper {
  border: 1px solid #ccc;
}

.DraftEditor-editorContainer::-webkit-scrollbar,
.table__variable::-webkit-scrollbar,
.table__result::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

.DraftEditor-editorContainer::-webkit-scrollbar-thumb,
.table__variable::-webkit-scrollbar-thumb,
.table__result::-webkit-scrollbar-thumb {
  background: #73367b;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.DraftEditor-editorContainer::-webkit-scrollbar-thumb:hover,
.table__variable::-webkit-scrollbar-thumb:hover,
.table__result::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.DraftEditor-editorContainer::-webkit-scrollbar-thumb:active,
.table__variable::-webkit-scrollbar-thumb:active,
.table__result::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.validations__text {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.validations__text button {
  width: 70%;
}

.info__adicional {
  width: 100%;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 17rem;
  margin-bottom: 2rem;
}

.info__adicional h6 {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.variable__select,
.list__select {
  width: 50%;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.variable__select .table__variable {
  height: 85%;
  overflow-y: scroll;
}

.table__variable td {
  width: 50%;
}

.select-multi-cat {
  width: 100%;
}

.title__templ {
  justify-content: space-between;
  align-items: center;
}

.modal_create_Template {
  background-color: white !important;
  box-shadow: 0px 0px 23px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 23px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 23px -2px rgba(0, 0, 0, 0.75);
}

.modal_create_document {
  &.hide {
    z-index: 0;
  }
  .modal-dialog {
    justify-content: center;
    display: flex;
    max-width: 100vw;
    min-height: 100vh;
    margin: 0;

    .modal-content {
      width: 90%;
      min-height: calc(100vh - 145px);
      margin-top: 95px;
      margin-bottom: 50px;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 0px 23px -2px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 0px 23px -2px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 23px -2px rgba(0, 0, 0, 0.75);

      .modal-body {
        padding: 0;
        background: url("../../static/fondo.jpg") no-repeat;
        background-size: cover;
        background-position: left;

        & > .row {
          min-height: calc(100vh - 145px);
          margin: 0;
        }
      }
    }
  }

  .modal_create_document_form {
    position: relative;
    padding: 1em;
    padding-top: 0 !important;
    min-height: calc(100vh - 300px) !important;

    @media (min-width: map-get($grid-breakpoints, "md")) {
      & {
        padding: 1em 3em;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "lg")) {
      & {
        padding: 1em 4em;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "xl")) {
      & {
        padding: 1em 5em;
      }
    }
  }

  .modal_create_document_preview {
    position: relative;
    background-color: $custom_purple;
    background: linear-gradient(225deg, $custom_purple 0%, #461b42 100%);
    padding: 2em;

    .close_modal_stepper {
      position: absolute;
      height: 40px;
      width: 40px;
      top: 0;
      right: 0;
      background-color: transparent;
      border: none;
      border-radius: 100%;
      & .cross-cls-1,
      & .cross-cls-2 {
        transition: fill 0.3s ease-in-out;
      }
      &:hover {
        .cross-cls-1 {
          fill: $custom_purple;
        }
        .cross-cls-2 {
          fill: white;
        }
      }
    }
    .preview_container {
      background-color: white;
      height:calc(100vh - 270px); //100%;
      overflow: hidden;
      overflow-y: scroll;
      border-radius: 15px;
      padding: 1em;

      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      .modal-title {
        font-weight: 600;
        margin-bottom: 1em;
        text-align: center;
        font-size: 20px;
      }
      p {
        margin-bottom: 1em;
        position: relative;
        * {
          position: inherit;
        }
        span {
          z-index: 2;
          position: inherit;
        }
        &::after {
          content: "";
          position: absolute;
          inset: 0;
          backdrop-filter: blur(3px);
          //background-color: rgba(0, 0, 0, 0.2);
          z-index: 1;
        }
      }
    }

    @media (min-width: map-get($grid-breakpoints, "md")) {
      & {
        padding: 3em;
      }
      .close_modal_stepper {
        top: 5px;
        right: 5px;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "xl")) {
      & {
        padding: 4em;
      }
      .preview_container {
        padding: 1.5em 2em;
        .modal-title {
          margin-bottom: 1.5em;
          font-size: 25px;
        }
      }
      .close_modal_stepper {
        top: 5px;
        right: 5px;
        height: 50px;
        width: 50px;
      }
    }
  }
}

.modal_create_Template,
.modal_create_document {
  & > .card-title {
    color: $custom_purple;
  }
  //.form-check-input:checked{

  //background-color: transparent;
  //border-color: rgb(0 0 0 / 25%);
  //background-image: none;
  //}
  .checked > input[type="radio"] {
    background-color: $custom_purple;
    border-color: $custom_purple;
    position: relative;
    &::after {
      position: absolute;
      inset: 3px;
      content: "";
      display: block;
      background-color: white;
      border-radius: 100%;
    }
  }

  .input-group {
    border: 2px solid $custom_purple;
    border-radius: 20px;
    &.disabled {
      opacity: 0.5;
    }
    &.invalid {
      border-color: red;
    }
    .input-group-text {
      background-color: $custom_purple;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      color: white;
      border: none;
    }

    .form-control {
      background-color: white;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }

  .validate-document-button {
    background-color: $custom_purple !important;
    width: 50%;
  }

  //section of draggables vars
  .card_variable_cont {
    background-color: white;
    border-radius: 30px;

    .card-title {
      font-weight: 600;
      text-align: center;
      color: $custom_purple;
    }

    .variable_draggable {
      padding: 0;
      min-height: 63px;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $custom_gray;
      background-color: white;
      .variable_name_cont {
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border: 2px solid rgba(0, 0, 0, 0.7);
          border-radius: 30px;
          padding: 0.375rem 2.25rem 0.375rem 0.75rem;
          margin: 0 calc(1.5rem * 0.5);
        }
      }
      &.invalid {
        .variable_name_cont {
          p {
            border-color: red;
          }
        }
      }

      .variable_input_cont {
        position: relative;
        padding: 0;

        label {
          color: $custom_purple;
          position: absolute;
          top: -27%;
          left: 15px;
          background-color: white;
          padding: 0px 6px 0 3px;
          z-index: 1;
        }

        .form-select,
        .search_select > div:first-of-type {
          border: 2px solid $custom_purple;
          border-radius: 30px;
        }

        .search_select > div:nth-of-type(2) {
          z-index: 2;
        }
      }
    }
  }
}

//stepper
.stepper_indicator {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 75%;
  margin: 0 auto;

  & > div {
    min-width: 10px;
    margin: 0 5px;
    height: 10px;
    border-radius: 20px;
    background-color: $custom_gray_transparent;
    transition: background-color 0.3s ease-in-out;

    &.active {
      background-color: $custom_purple;
    }
  }
}

.stepper_page_component_container {
  height: calc(100vh - 270px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 3em 10px 1.5em 0;

  h3 {
    color: $custom_purple;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 1.2em;
  }
  & > p {
    line-height: 01.1;
    font-size: 15px;
  }

  label {
    text-transform: capitalize;
  }

  .input_stepper {
    margin-bottom: 15px;

    .input_vars {
      border: 2px solid $custom_purple;
      padding: 2px 10px;
      height: 30px;
      border-radius: 15px;
    }
    textarea.input_vars {
      height: 90px;
    }
  }
  // &::-webkit-scrollbar{
  //   width: 8px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: $custom_purple;    /* color of the scroll thumb */
  //   border-radius: 20px;       /* roundness of the scroll thumb */
  //   border: 3px solid $custom_purple;  /* creates padding around scroll thumb */
  // }
}

.prev_step_btn,
.next_step_btn {
  height: 35px;
  width: 30%;
  min-width: 50px;
  max-width: 300px;
  margin: 0 5px;
  border-radius: 15px;
  border: none;
  color: white;
  &:disabled {
    opacity: 0.5;
  }
}

.prev_step_btn {
  background-color: $custom_gray;
}

.next_step_btn {
  background-color: $custom_purple;
}

.multiSelectContainer ul {
  background-color: white;
}
.input-group-multiselect {
  & > div {
    flex: 1;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;

    .searchWrapper {
      border: none;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .optionListContainer {
      width: 100%;
    }

    .highlightOption {
      background-color: white;
      color: black;
    }
    .chip,
    .multiSelectContainer li:hover {
      background-color: $custom_purple;
      color: white;
    }
  }
}
